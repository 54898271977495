// fontawesomeライブラリをインポート
@import '~@fortawesome/fontawesome-free/css/all.min.css';
// izitoastライブラリをインポート
@import '~izitoast/dist/css/iziToast.css';

@import 'styles/constant';

body {
  color: $PLANE_TEXT_COLOR;
}

a {
  color: $LINK_COLOR;
  text-decoration: none;
}

hr {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $LINE_COLOR;
  border: 0;
  margin: 0px;
}

// テキストボックス、セレクタの設定
input[type='text'], input[type='password'] , select {
  color: $PLANE_TEXT_COLOR;
  box-sizing: border-box;
  border: 1px solid $LINE_COLOR;
  border-radius: 10px;
  font-size: 16px;
  padding: 2px 8px;
  box-shadow: none;

  &::placeholder {
    color: $PLACE_HOLDER_COLOR;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 1pt 1pt $MAIN_COLOR;
  }
}

select {
  border: 1px solid $LINE_COLOR;
  padding: 8px;
  width: 300px;
}

// チェックボックスの設定
input[type='checkbox'] {
  border: 2px solid $LINE_COLOR;
  border-radius: 50%;
  margin: 4px;
  cursor: pointer;
}

// 汎用ボタンの設定
.button {
  font-size: 14px;
  color: $PLANE_TEXT_COLOR;
  padding: 5px 10px;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 0 1pt 1pt $MAIN_COLOR;
  }
}

// リンクボタンの設定
.linkButton {
  color: $LINK_COLOR;
  cursor: pointer;
}

// 画面右下に固定したボタンの設定
.fixLowerRightButton {
  background-color: $SUB_COLOR;
  color: $WHITE_TEXT_COLOR;
  font-size: 36px;
  width: 50px;
  height: 50px;
  line-height: 45px;
  border-radius: 50%;
  border: 1px solid $SUB_COLOR;
  position: fixed;
  bottom: 10px;
  right: 10px;
  text-align:center;
  cursor: pointer;
  z-index: $FIX_LOWER_RIGHT_BUTTON_ZINDEX;

  &:hover {
    border-color: $MAIN_COLOR;
  }
}

// モーダル関連の設定
.modalOverray {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: $OVERLAY_COLOR;
  z-index: $MODAL_OVERRAY_ZINDEX;
}

.modalContent {
  position: relative;
  max-height: 85%;
  margin: 20px auto;
  max-width: 800px;
  right: unset;
  bottom: unset;
  border: 1px solid #cccccc;
  background: white;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  overflow: scroll;

  .modalTitle {
    font-size: 22px;
    color: $MAIN_COLOR;
  }

  .modalSupportMessage {
    font-size: 14px;
    color: $PLANE_TEXT_COLOR;
  }
}

@include mq(sp) {
  .modalContent {
    margin: 10px;
  }
}

.closeModalButton {
  position: absolute;
  top: 5px;
  right: 5px;
  color: $PLANE_TEXT_COLOR;
  font-size: 20px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  white-space: pre-wrap;
  text-align: center;
  cursor: pointer;
}

// セクションタイトル
.sectionTitle {
  display: flex;
  padding: 4px 0px;
  font-size: 18px;
  color: $MAIN_COLOR;
  border-bottom: 1px solid $LINE_COLOR;
  
  .main {
    flex: 1 1 0;
  }

  .action {
    cursor: pointer;
    margin: 0 10px;
  }
}

// Diff%ごとの文字色
.NO_DIFF {
  color: $MAIN_COLOR;
}
.DIFF_20_LESS {
  color: $LINK_COLOR;
}
.DIFF_40_LESS {
  color: $SUB_COLOR;
}
.DIFF_60_LESS {
  color: $PINK_COLOR;
}
.DIFF_80_LESS {
  color: $WARNING_COLOR;
}
.DIFF_100_LESS {
  color: $ERROR_COLOR;
}

// スクロール
.scroll {
  height: 100%;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: calc(.6rem + 2px);
  height: 1px;
}

::-webkit-scrollbar-track {
  margin: 1px;
}
::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.5);
  border-radius: 100px;
  border: solid transparent;
  background-clip: content-box;
  border-width: 1px 2px;
}

// 非表示
.hidden {
  display: none;
}